.footer-container {
  height: 'auto';
  background: #7a4495;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 10px;

  a {
    text-decoration: none;
    color: white;
  }

  .footer-details {
    h6 {
      font-size: 20px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 33px;
      letter-spacing: 0.04em;
    }

    .social-icon-cont {
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 20px;

      .facebook-icon {
        color: #7a4495;
        width: 25px;
        height: 25px;
      }
    }
  }
}

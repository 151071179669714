@font-face {
  font-family: SVN-Poppins-Black;
  src: url(../assets/fonts/SVN-Poppins-Black.ttf);
}
@font-face {
  font-family: SVN-Poppins-Bold;
  src: url(../assets/fonts/SVN-Poppins-Bold.ttf);
}
@font-face {
  font-family: SVN-Poppins-Light;
  src: url(../assets/fonts/SVN-Poppins-Light.ttf);
}
@font-face {
  font-family: SVN-Poppins-Medium;
  src: url(../assets/fonts/SVN-Poppins-Medium.ttf);
}
@font-face {
  font-family: SVN-Poppins-Regular;
  src: url(../assets/fonts/SVN-Poppins-Regular.ttf);
}
@font-face {
  font-family: SVN-Poppins-SemiBold;
  src: url(../assets/fonts/SVN-Poppins-SemiBold.ttf);
}

.section {
  margin-top: 100px;
  margin-bottom: 100px;
}

button {
  text-transform: unset !important;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #f9f9fb !important;
}
@media only screen and (min-width: 1600px) {
  .MuiContainer-fixed {
    max-width: 1470px !important;
  }
}

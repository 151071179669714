.navigation-container {
  background-color: white;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.25) !important;

  .navigation-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .logo {
        width: 136px;
        height: 50.58px;
      }
    }

    .right ul {
      display: flex;
      list-style: none;
      align-items: center;

      li {
        margin: 0 20px;
        font-weight: 800;
        color: #696984;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}

.navigation-bottom {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;

    li {
      margin: 0 20px;
      color: #696984;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

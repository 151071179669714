.themed-classes-container {
  .heading {
    font-weight: 600;
    font-size: 38px;
    line-height: 180%;
    text-align: center;
    color: #932191;
  }

  .details {
    margin-top: 50px;

    .title {
      font-weight: 800;
      font-size: 22px;
      line-height: 160%;
      color: #696984;
      margin-bottom: 16px;
    }

    .desc {
      font-weight: 400;
      font-size: 22px;
      line-height: 160%;
      color: #696984;
    }
  }
}

.banner-container {
  .left {
    p {
      font-weight: 400;
      font-size: 18px;
      color: #696984;
      margin: 10px 0;
    }
  }

  .right img {
    width: 100%;
    max-width: 90vw;
    height: auto;
  }
}

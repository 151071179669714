.footer-top-container {
  height: 322px;
  background: #f7f1fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .heading {
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    color: #932191;
  }

  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #696984;
    margin-top: 10px;
  }

  button {
    width: 250px;
    height: 70px;
    background: #932191;
    border-radius: 30px;
    color: white;
    border: 0;
    outline: 0;
    font-weight: 700;
    font-size: 20px;
    margin-top: 25px;
  }
}
